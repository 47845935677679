import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w11GB03Buttercreme = () => (
  <Layout>
    <SEO title="w11GB03Buttercreme" />

    <h1 id="ueberschrift">Gemeinsam Backen</h1>
    <h2>Buttercreme-Verzierungen &emsp;  ©&nbsp;2003</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w11GB03Buttercreme&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Wie ein Sprichwort treffend besagt: "Das Auge isst mit"
      </p>
      <p>
        Gemeinsam mit anderen können Sie Ihr künstlerisches Talent suchen und entdecken, indem Sie 
        unter Anleitung verschiedene Dekorationen aus Buttercreme anfertigen.
        Genießen Sie die Zeit beim geselligen Experimentieren, Fluchen, Beneiden und Freuen.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitz- oder Stehplatz pro Teilnehmer + 1 Tisch und Sitzplatz extra<br/>
        Materialbedarf: keines
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze und 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w11GB03Buttercreme
